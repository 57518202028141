import Axios from "axios";
import Loader from "./common/Loader";
import React, { useEffect, useState } from "react";
import DateBox from "./controls/DateBox";
import * as constants from "../constants";
import moment from "moment";
import Transition from "./common/Transition";
import { DateFormatter } from "./common/DateFormatter";
import TableComponent from "./common/TableComponent";
import MultiSelection from "./controls/MultiSelection";
import "../assets/css/additional.css";
import { AgentsRequests } from "../apiRequests/AgentsRequests";
import ToolTip from "./common/ToolTip";
import { currencyFormat, multiselectFilter } from "./common/commonFns";
import SearchBox from "./controls/SearchBox";
import Uploader from "./controls/Uploader";

const dateFormatter = new DateFormatter();
const agentsRequests = new AgentsRequests();
const svgs = {
  attachment: (
    <svg
      className="w-5 h-5"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
};

const PendingProposals = () => {
  const [state, setState] = useState({
    uploadFile: null,
    isOpen: false,
    tableEmpty: "hidden",
    background: "hidden",
    uploaderBkgrd: "hidden",
    dataLength: 0,
    tableRows: [],
    isLoaded: false,
    filterOn: false,
    hidden: "hidden",
    expanded: "",
    allPrograms: [],
    allCampuses: [],
    allIntakes: [],
    fromDate: "",
    toDate: "",
    allAccountManagers: [],
    programsSelectedList: [],
    campusSelectedList: [],
    intakesSelectedList: [],
    acManagerSelectedList: [],
    pProposalData: [],
    PendingProposaloData: [],
    uploaderHidden: "hidden",
    agentId: localStorage.getItem("agentId"),
    sortBy: "",
    fileObjectID: null,
    firstRowIndex: 0,
    lastRowIndex: constants.NUMBEROFROWS - 1,
    upAlphabetSvg: "gray",
    searchFieldValue: "",
    alphabeticOrder: "alphabetic",
    commissionOrder: "commissionLow",
    downAlphabetSvg: "gray",
    upCommissionnSvg: "gray",
    downCommissionnSvg: "gray",
    tooltipHidden: true,
    fileProposalID: null,
  });

  useEffect(() => {
    let startDate = localStorage.getItem("t1StartDate");
    let endDate = localStorage.getItem("t1EndDate");

    // following line of code will turn on the loader
    setState((prevState) => ({
      ...prevState,
      fromDate: startDate,
      toDate: endDate,
    }));
    pendingProposalData(startDate, endDate);
  }, []);

  // following function will make a new request for data
  const pendingProposalData = (startDate, endDate) => {
    setState((prevState) => ({
      ...prevState,
      hidden: "",
      isLoaded: true
    }));
    agentsRequests.pendingProposalRequest(startDate, endDate).then((result) => {
      loadMultiSelectData(result);
    });
  };

  // following function will generate data for all Multiselect fields
  const loadMultiSelectData = (pProposalData) => {
    let allPrograms = [];
    let allCampuses = [];
    let allIntakes = [];
    let allAccountManagers = [];
    let data = pProposalData;

    data.map((object) => {
      if (allPrograms.some((item) => object.courseName === item)) {
      } else {
        allPrograms.push(object.courseName);
      }
    });

    data.map((object) => {
      if (allCampuses.some((item) => object.campus === item)) {
      } else {
        allCampuses.push(object.campus);
      }
    });

    data.map((object) => {
      if (
        allIntakes.some((item) => object.intake === item) ||
        object.intake === ""
      ) {
      } else {
        allIntakes.push(object.intake);
      }
    });

    data.map((object) => {
      if (
        allAccountManagers.some((item) => object.accountManagerName === item)
      ) {
      } else {
        allAccountManagers.push(object.accountManagerName);
      }
    });
    setState((prevState) => ({
      ...prevState,
      allPrograms: allPrograms,
      allCampuses: allCampuses,
      allIntakes: allIntakes,
      allAccountManagers: allAccountManagers,
      pProposalData: pProposalData,
    }));

    setState((prevState) => ({
      ...prevState,
      hidden: "hidden",
      isLoaded: false
    }));
  };

  // following function will firstly make function calls to apply filters if any and
  // then will process the data to display in the table and then store that data in
  // tableRows Array
  const loadData = () => {
    let rowItem = {};
    let tableRows = [];
    let buttonIndex = 0;
    let data = state.pProposalData;

    let firstRowIndex = state.firstRowIndex;
    let lastRowIndex = state.lastRowIndex;

    // following call will filter data
    data = dataProcessor(data);

    data = dataSortingFn(data);

    let dataLength = data.length;

    if (dataLength < parseInt(firstRowIndex) + 1) {
      firstRowIndex = 0;
      lastRowIndex = constants.NUMBEROFROWS - 1;
    }

    if (data != "" && data != null) {
      data.map((object, index) => {
        if (index >= firstRowIndex && index <= lastRowIndex) {
          rowItem.proposalID = object.proposalID;
          rowItem.ImgSrc =
            "https://ui-avatars.com/api/name=" +
            object.studentName +
            "/?background=random";
          rowItem.Label1 = object.studentName;
          rowItem.PillTxt = object.proposalNo;
          rowItem.PillClr = "green";
          let pDate = moment(object.proposalDate, "DD/MM/YYYY");
          rowItem.DescriptionClm1 =
            "Processed On: " + moment(pDate).format("DD-MM-YYYY");

          rowItem.Label2 = object.courseName;
          let sDate = moment(object.courseStartDate, "DD/MM/YYYY");
          let eDate = moment(object.courseEndDate, "DD/MM/YYYY");
          rowItem.DescriptionClm2 =
            moment(sDate).format("DD-MM-YYYY") +
            " to " +
            moment(eDate).format("DD-MM-YYYY");

          rowItem.Campus = object.campus;

          rowItem.Value = "$" + currencyFormat(object.commission);

          if (object.accountManagerName) {
            rowItem["Account Manager"] = object.accountManagerName;
          } else {
            rowItem["Account Manager"] = "N/A";
          }

          if (object.intake) {
            rowItem.Label2 = rowItem.Label2 + " (" + object.intake + ")";
          } else {
            rowItem.Label2 = rowItem.Label2 + "";
          }
          // rowItem[" "] = (
          //   <div className="relative flex justify-end">
          //     <button
          //       onClick={() => {
          //         eventHandeler(index, "attachment");
          //       }}
          //       className="mx-2 my-1 w-5 h-5 inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          //     >
          //       <div className="tooltip">
          //         {svgs.attachment}
          //         <ToolTip text={"Click to upload a File"} />
          //       </div>
          //     </button>
          //   </div>
          // );

          tableRows.push({ ...rowItem });
          buttonIndex = buttonIndex + 1;
        }
      });
      if (dataLength < state.pProposalData.length) {
        setState((prevState) => ({ ...prevState, filterOn: true }));
      } else {
        setState((prevState) => ({ ...prevState, filterOn: false }));
      }
    } else {
      //following line will unhide Nodata View
      setState((prevState) => ({ ...prevState, tableEmpty: "" }));
    }

    setState((prevState) => ({ ...prevState, tableRows: tableRows }));
  };

  const setPageIndexFn = (firstRowIndex, lastRowIndex) => {
    // console.log(firstRowIndex);
    // console.log(lastRowIndex);

    setState((prevState) => ({
      ...prevState,
      firstRowIndex: firstRowIndex,
      lastRowIndex: lastRowIndex,
    }));
  };

  // following function will render the Table component
  const renderTable = () => {
    let appDetailsBtn = tableHeadingButton(
      "Student Details",
      state.alphabeticOrder,
      state.upAlphabetSvg,
      state.downAlphabetSvg
    );
    let commissionBtn = tableHeadingButton(
      "Commission",
      state.commissionOrder,
      state.upCommissionnSvg,
      state.downCommissionnSvg
    );
    let tableHeadings = [
      "Student Details",
      "Program Details",
      "Campus",
      "Account Manager",
      commissionBtn,
      " ",
    ];
    let tableRows = [...state.tableRows];

    if (tableRows.length >= 1) {
      return (
        <TableComponent
          tableRows={tableRows}
          tableHeadings={tableHeadings}
          filterOn={state.filterOn}
          dataLength={state.dataLength}
          setPageIndexFn={setPageIndexFn}
          firstRowIndex={state.firstRowIndex}
          lastRowIndex={state.lastRowIndex}
          clmTwoWidth={"w-1/4"}
          numberOfElementsPerPage={constants.NUMBEROFROWS}
        />
      );
    } else {
      return (
        <div
          className={
            state.tableEmpty + " text-gray-600 w-full flex justify-center mt-20"
          }
        >
          <h1>
            There is No Data Available to Display, please reset filters if any
            applied.
          </h1>
        </div>
      );
    }
  };

  const dataSortingFn = (data) => {
    let sortBy = state.sortBy;
    if (sortBy === "alphabetic") {
      data.sort((a, b) => a.studentName.localeCompare(b.studentName));
    } else if (state.sortBy === "reverseAlphabetic") {
      data.sort((a, b) => b.studentName.localeCompare(a.studentName));
    } else if (state.sortBy === "commissionLow") {
      data.sort(function (a, b) {
        return b.commission - a.commission;
      });
    } else if (state.sortBy === "commissionHigh") {
      data.sort(function (a, b) {
        return a.commission - b.commission;
      });
    }

    return data;
  };

  const dataProcessor = (data) => {
    let originalData = data;

    let programsSelectedList = state.programsSelectedList;
    let campusSelectedList = state.campusSelectedList;
    let intakesSelectedList = state.intakesSelectedList;
    let acManagerSelectedList = state.acManagerSelectedList;

    if (state.searchFieldValue != "") {
      let searchValue = state.searchFieldValue;
      document.getElementById("search").value = searchValue;
      data = data.filter((object) =>
        object.studentName.toUpperCase().includes(searchValue.toUpperCase())
      );
      setState((prevState) => ({ ...prevState, filterOn: true }));
      if (data.length === originalData.length) {
        setState((prevState) => ({ ...prevState, filterOn: false }));
      }
    }

    if (programsSelectedList != "") {
      data = multiselectFilter(data, programsSelectedList, "courseName");
    }
    if (campusSelectedList != "") {
      data = multiselectFilter(data, campusSelectedList, "campus");
    }
    if (intakesSelectedList != "") {
      data = multiselectFilter(data, intakesSelectedList, "intake");
    }

    if (acManagerSelectedList != "") {
      data = multiselectFilter(
        data,
        acManagerSelectedList,
        "accountManagerName"
      );
    }
    // following line of code will set dataLength
    setState((prevState) => ({ ...prevState, dataLength: data.length }));
    return data;
  };

  const tableHeadingButton = (name, order, upSvgClr, downSvgClr) => {
    return (
      <div>
        <div className="hidden lg:block">
          {name}
          <button
            onClick={() => {
              sortingEventHandeler(order);
            }}
            className="ml-2 w-7 h-4 inline-flex items-center justify-center border-2 border-transparent hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          >
            <svg
              className="w-3 h-4 -rotate-180 transform "
              stroke={upSvgClr}
              strokeWidth="2"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              className="w-3 h-4"
              stroke={downSvgClr}
              strokeWidth="2"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div className="lg:hidden">{name}</div>
      </div>
    );
  };

  const sortingEventHandeler = (type) => {
    if (type === "alphabetic") {
      setState((prevState) => ({
        ...prevState,
        sortBy: "alphabetic",
        alphabeticOrder: "reverseAlphabetic",
        upAlphabetSvg: "blue",
        downAlphabetSvg: "gray",
        upCommissionnSvg: "gray",
        downCommissionnSvg: "gray",
      }));
    } else if (type === "reverseAlphabetic") {
      setState((prevState) => ({
        ...prevState,
        sortBy: "reverseAlphabetic",
        alphabeticOrder: "alphabetic",
        upAlphabetSvg: "gray",
        downAlphabetSvg: "blue",
        upCommissionnSvg: "gray",
        downCommissionnSvg: "gray",
      }));
    } else if (type === "commissionHigh") {
      setState((prevState) => ({
        ...prevState,
        sortBy: "commissionHigh",
        commissionOrder: "commissionLow",
        upAlphabetSvg: "gray",
        downAlphabetSvg: "gray",
        upCommissionnSvg: "blue",
        downCommissionnSvg: "gray",
      }));
    } else if (type === "commissionLow") {
      setState((prevState) => ({
        ...prevState,
        sortBy: "commissionLow",
        commissionOrder: "commissionHigh",
        upAlphabetSvg: "gray",
        downAlphabetSvg: "gray",
        upCommissionnSvg: "gray",
        downCommissionnSvg: "blue",
      }));
    }
  };

  const eventHandeler = (index, event) => {
    const fileProposalID = { ...state.tableRows[index]?.proposalID };
    if (event === "attachment" && fileProposalID) {
      // console.log("Attachment clicked PID: " + fileProposalID);
      setState((prevState) => ({
        ...prevState,
        uploaderBkgrd: "",
        uploaderHidden: "",
        fileProposalID: fileProposalID,
      }));
    } else if (event === "close") {
      setState((prevState) => ({
        ...prevState,
        uploaderBkgrd: "hidden",
        uploaderHidden: "hidden",
      }));
    }
  };

  const searchHandeler = () => {
    var searchValue = document.getElementById("search").value;
    setState((prevState) => ({
      ...prevState,
      searchFieldValue: searchValue,
    }));
  };

  const onMultiSelectChange = (selectedList, item, id) => {
    if (id === "program") {
      setState((prevState) => ({
        ...prevState,
        programsSelectedList: selectedList,
      }));
    } else if (id === "campus") {
      setState((prevState) => ({
        ...prevState,
        campusSelectedList: selectedList,
      }));
    } else if (id === "intake") {
      setState((prevState) => ({
        ...prevState,
        intakesSelectedList: selectedList,
      }));
    } else if (id === "acManager") {
      setState((prevState) => ({
        ...prevState,
        acManagerSelectedList: selectedList,
      }));
    }
  };

  const filtersDropdown = () => {
    let expanded = state.expanded;
    if (expanded) {
      setState((prevState) => ({
        ...prevState,
        isOpen: false,
        expanded: "",
        background: "hidden",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isOpen: true,
        expanded: "yes",
        background: "",
      }));
    }
  };

  const resetDatesFn = () => {
    setState((prevState) => ({
      ...prevState,
      fromDate: "",
      toDate: "",
      // isOpen: false,
      // expanded: "",
      // background: "hidden",
      // filterPanelHeight: "0",
    }));
  };

  const handleDateBox = (selectedDate, name) => {
    let fromDate = new Date(dateFormatter.formatterThree(state.fromDate));
    let toDate = new Date(dateFormatter.formatterThree(state.toDate));
    let sDate = new Date(dateFormatter.formatterThree(selectedDate));
    localStorage.setItem("dateRangeLabel1", "Custom Range");
    if (name === "FromDate") {
      let startDate = dateFormatter.formatterTwo(sDate);
      let endDate = dateFormatter.formatterTwo(toDate);
      localStorage.setItem("t1StartDate", startDate);
      setState((prevState) => ({
        ...prevState,
        fromDate: startDate,
        isOpen: false,
        expanded: "",
        background: "hidden",
      }));
    } else if (name === "ToDate") {
      let startDate = dateFormatter.formatterTwo(fromDate);
      let endDate = dateFormatter.formatterTwo(sDate);
      localStorage.setItem("t1EndDate", endDate);
      setState((prevState) => ({
        ...prevState,
        toDate: endDate,
        isOpen: false,
        expanded: "",
        background: "hidden",
      }));
    }
  };

  const closeAdvSearch = () => {
    setState((prevState) => ({
      ...prevState,
      isOpen: false,
      expanded: "",
      background: "hidden",
    }));
  }

  useEffect(() => {
    if (state.fromDate && state.toDate) {
      pendingProposalData(state.fromDate, state.toDate);
    }
  }, [state.fromDate, state.toDate]);

  const renderAdvancedSearch = () => {
    return (
      <div>
        <div className="md:mt-5 mt-1 flex xl:justify-end">
          <span
            onClick={() => filtersDropdown()}
            className="  p-2 h-10 rounded-md bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 cursor-pointer"
          >
            Advanced Search
          </span>
        </div>
        <Transition
          show={state.isOpen}
          enter="transition ease-in duration-300 transform"
          enterFrom="opacity-25 scale-0"
          enterTo="opacity-100 scale-100"
          leave="transition ease-out duration-500 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-25 scale-0"
        >
          <div className="origin-top right-0 absolute z-10 mb-8 w-full bg-gray-200">
            <div className="px-2 pt-4 lg:mt-0 flex-shrink-0 bg-gray-100 sm:rounded-lg border-b border-gray-200">
              <div className={"mx-4 mb-4 bg-gray-100"}>
                <div className="pb-4">
                  <div
                    className={"relative text-gray-600 w-full flex justify-center mb-4"}
                  >
                    <h1 className="font-semibold">Filters</h1>
                    <p className="cursor-pointer absolute right-0" onClick={closeAdvSearch}>&#10006;</p>
                  </div>
                  <div className=" grid grid-cols-6 gap-6">
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="program"
                        label="Select Programs"
                        selectedValues={state.programsSelectedList}
                        data={state.allPrograms}
                        onSelect={(selectedList, selectedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "program"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "program"
                          )
                        }
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="campus"
                        label="Select Campuses"
                        selectedValues={state.campusSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "campus"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "campus"
                          )
                        }
                        data={state.allCampuses}
                        placeholder="Select"
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="acManager"
                        label="Select Account Managers"
                        selectedValues={state.acManagerSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "acManager"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "acManager"
                          )
                        }
                        data={state.allAccountManagers}
                        placeholder="Select"
                      />
                    </div>
                    <div
                      className={
                        (state.allIntakes != "" ? "" : "hidden") +
                        " md:py-3 col-span-6 md:col-span-3 "
                      }
                    >
                      <MultiSelection
                        id="intake"
                        label="Select Intakes"
                        selectedValues={state.intakesSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "intake"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "intake"
                          )
                        }
                        data={state.allIntakes}
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <div className="">
          <span
            onClick={() => filtersDropdown()}
            className={
              state.background +
              " z-6 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-gray-400 opacity-75"
            }
          />
        </div>
      </div>
    );
  };

  const upload = (file, docTypeId) => {
    setState((prevState) => ({ ...prevState, uploadFile: file }));
  };

  const uploadFile = async () => {
    var file = state.uploadFile;
    const objectType = 29; //proposal type
    const objectID = parseInt(state.fileProposalID); //Applicant Id
    if (file) {
      if (file.length > 0) {
        setState((prevState) => ({
          ...prevState,
          hidden: "",
          isLoaded: true,
        }));

        let form = new FormData();

        form.append("file", file[0]);
        form.append("objectId", objectID);
        form.append("objectType", objectType);
        form.append("isPublic", 1);

        try {
          Axios.post(constants.BASEURL + "Document", form).then((result) => {
            if ((result.data.sucess = false)) {
              alert("Fail");
            } else {
              alert("Success");
              setState((prevState) => ({
                ...prevState,
                uploaderHidden: "hidden",
                uploaderBkgrd: "hidden",
                hidden: "hidden",
                isLoaded: false,
              }));
            }
          });
        } catch (e) {
          alert("Error while uploading ");
        }
      }
    }
  };
  useEffect(() => {
    loadData();
  }, [
    state.allPrograms,
    state.allCampuses,
    state.allIntakes,
    state.allAccountManagers,
    state.pProposalData,
    state.searchFieldValue,
    state.firstRowIndex,
    state.lastRowIndex,
    state.sortBy,
    state.commissionOrder,
    state.upAlphabetSvg,
    state.downAlphabetSvg,
    state.upCommissionnSvg,
    state.downCommissionnSvg,
    state.isOpen,
    state.expanded,
    state.background,
    state.fromDate,
    state.toDate,
  ]);
  // following function will rende main GUI
  return (
    <div className="relative mt-6 align-middle min-h-full">
      <Loader id={"t1"} loaded={state.isLoaded} hidden={state.hidden}></Loader>
      <div className="rounded-md p-4 mb-4 md:mb-8 w-full grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-6 bg-gray-100">
        <div className="xl:col-span-2">
          <SearchBox
            id={"search"}
            label={"Search For Student Name"}
            svgType={"student"}
            placeholder={"Name"}
            value={state.searchFieldValue}
            searchHandler={() => {
              searchHandeler();
            }}
          />
        </div>

        <div className="xl:col-span-1">
          <DateBox
            dependentOn={true}
            id={"Pd1"}
            label="Processed From Date"
            //minDate={moment().add(0, "days").toDate()}
            onChange={handleDateBox}
            name={"FromDate"}
            value={state.fromDate ? new Date(state.fromDate) : ""}
          />
        </div>
        <div className="xl:col-span-1">
          <DateBox
            dependentOn={true}
            id={"Pd1"}
            label="Processed Till Date"
            //minDate={moment().add(0, "days").toDate()}
            onChange={handleDateBox}
            name={"ToDate"}
            value={state.toDate ? new Date(state.toDate) : ""}
            //onChange={this.handleChange}
          />
        </div>
        <div className="xl:col-span-2">{renderAdvancedSearch()}</div>
      </div>
      <div
        className={
          state.uploaderHidden + " z-40 child w-70per h-60per bg-yellow-100 p-6"
        }
      >
        <div className="h-80percent">
          <Uploader
            onUpload={(file, docTypeId) => {
              upload(file, docTypeId);
            }}
            docTypeId={"AgentDocuments"}
          />
        </div>
        <div className="sm:border-t sm:border-gray-200 sm:pt-5 w-full flex justify-center">
          <button
            id="submit"
            type="button"
            onClick={() => {
              uploadFile();
            }}
            className="inline-flex justify-center py-1 px-4 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
          >
            Submit
          </button>
        </div>
      </div>
      <div className="mb-6 w-full align-middle">{renderTable()}</div>
      <div className="">
        <span
          onClick={() => eventHandeler(0, "close")}
          className={
            state.uploaderBkgrd +
            " z-30 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-black opacityBackground"
          }
        />
      </div>
    </div>
  );
};

export default PendingProposals;
