import React, { useState, useEffect } from "react";
import moment from "moment";
import DataTile from "./controls/DataTile";
import DateBox from "./controls/DateBox";
import SearchBox from "./controls/SearchBox";
import Axios from "axios";
import { AgentsRequests } from "../apiRequests/AgentsRequests";
import { DateFormatter } from "./common/DateFormatter";
import Uploader from "./controls/Uploader";
import * as constants from "../constants";
import Loader from "./common/Loader";

const dateFormatter = new DateFormatter();
const agentsRequests = new AgentsRequests();

const DocumentsView = () => {
  const [dataEmpty, setDataEmpty] = useState("hidden");
  const [hidden, setHidden] = useState("hidden");
  const [isLoaded, setIsLoaded] = useState(false);
  const [documentsData, setDocumentsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [filteredDocumentsData, setFilteredDocumentsData] = useState([]);
  const [fromDateState, setFromDate] = useState(localStorage.getItem("t4StartDate"));
  const [toDateState, setToDate] = useState(localStorage.getItem("t4EndDate"));
  const [expanded, setExpanded] = useState("");
  const [value, setValue] = useState("");
  const [background, setBackground] = useState("hidden");
  const [uploadFileState, setUploadFileState] = useState(null);
  function agentDocumentsFn(startDate, endDate) {
    setHidden("");
    setIsLoaded(true);
    agentsRequests.agentDocuments(startDate, endDate).then((result) => {
      let documentsData = result;
      if (documentsData !== "error") {
        setDocumentsData(documentsData);
        setFilteredDocumentsData(documentsData);
        setHidden("hidden");
        setIsLoaded(false);
      } else {
        setDataEmpty("");
      }
    });
  };

  const searchHandeler = () => {
    var searchValue = document.getElementById("search").value;
    setValue(searchValue);
    let data = documentsData;

    if (searchValue !== "") {
      data = data.filter((object) =>
        object.docName.toUpperCase().includes(searchValue.toUpperCase())
      );
    }

    if (data.length === 0) {
      setDataEmpty("");
    } else {
      setDataEmpty("hidden");
    }

    setFilteredDocumentsData(data);
  };

  const dateStr = (date) => {
    let uDate = moment(date, "DD/MM/YYYY");
    return "Uploaded on: " + moment(uDate).format("DD-MM-YYYY");
  };

  const handleDateBox = (e, name) => {
    let selectedDate = e;
    const fromDate = new Date(dateFormatter.formatterThree(fromDateState));
    let toDate = new Date(dateFormatter.formatterThree(toDateState));
    let sDate = new Date(dateFormatter.formatterThree(selectedDate));

    if (name === "FromDate") {
      let startDate = dateFormatter.formatterTwo(sDate);
      let endDate = dateFormatter.formatterTwo(toDate);
      localStorage.setItem("t4StartDate", startDate);
      localStorage.setItem("dateRangeLabel4", "Custom Range");
      setFromDate(selectedDate);
      setIsOpen(false);
      setExpanded("");
      setBackground("hidden");
      agentDocumentsFn(startDate, endDate);
    } else if (name === "ToDate") {
      let startDate = dateFormatter.formatterTwo(fromDate);
      let endDate = dateFormatter.formatterTwo(sDate);
      localStorage.setItem("t4EndDate", endDate);
      localStorage.setItem("dateRangeLabel4", "Custom Range");
      setToDate(selectedDate);
      setIsOpen(false);
      setExpanded("");
      setBackground("hidden");
      agentDocumentsFn(startDate, endDate);
    }
  };

  const uploaderPanel = () => {
    if (expanded) {
      setIsOpen(false);
      setExpanded("");
      setBackground("hidden");
    } else {
      setIsOpen(true);
      setExpanded("yes");
      setBackground("");
    }
  };

  const renderTiles = () => {
    let documentsData = filteredDocumentsData;
    if (documentsData.length >= 1) {
      return (
        <div className="bg-gray-100 h-70vh  shadow rounded-lg overflow-auto">
          <div className="p-4 mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5 ">
            {documentsData.map((object, index) => (
              <div key={"id" + index}>
                <DataTile
                  id={"id" + index}
                  svgType={object.docName.slice(
                    (Math.max(0, object.docName.lastIndexOf(".")) || Infinity) +
                    1
                  )}
                  title={object.docName}
                  description2={
                    <div className="grid grid-cols-7">
                      <div className="col-span-6 text-xxs">
                        <p> {"Size: " + object.docSize}</p>
                      </div>
                      <div className="col-span-1 pt-3">
                        <a
                          className="hover:text-blue-700 text-sm text-gray-600 hover:font-semibold cursor-pointer flex justify-end"
                          href={object.downloadLink}
                        >
                          <svg
                            className="w-4 h-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  }
                  description1={
                    <div className="text-xxs text-gray-500">
                      {dateStr(object.uploadDate)}
                    </div>
                  }
                />
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={
            dataEmpty +
            " text-gray-600 w-full flex justify-center mt-20"
          }
        >
          <h1>
            There is No Data Available to Display, please reset filters if any
            applied.
          </h1>
        </div>
      );
    }
  };

  const upload = (file, docTypeId) => {
    setUploadFileState(file);
  };

  const uploadFile = async () => {
    var file = uploadFileState;
    const objectType = 4;
    const objectID = localStorage.getItem("agentId"); //Applicant Id
    if (file) {
      if (file.length > 0) {
        setHidden("");
        setIsLoaded(true);
        let form = new FormData();

        form.append("file", file[0]);
        form.append("objectId", objectID);
        form.append("objectType", objectType);
        form.append("isPublic", 1);

        try {
          Axios.post(constants.BASEURL + "Document", form).then((result) => {
            if ((result.data.sucess = false)) {
              alert("Fail");
            } else {
              setHidden("hidden");
              setIsLoaded(false);
              setIsOpen(false);
              setBackground("hidden");
              alert("Success");
            }
          });
        } catch (e) {
          alert("Error while uploading ");
        }
      }
    }
  };

  useEffect(() => {
    let startDate = dateFormatter.formatterTwo(localStorage.getItem("t4StartDate"));
    let endDate = dateFormatter.formatterTwo(localStorage.getItem("t4EndDate"));
    agentDocumentsFn(startDate, endDate);
    setDataEmpty("")
  }, []);

  return (
    <div className="relative mt-6 align-middle">
      <span
        className={
          hidden +
          " z-30 fixed top-10 left-0 right-0 bottom-0 w-full bg-white opacity-25"
        }
      />
      <Loader loaded={isLoaded} id={"documentview"} hidden={hidden} />
      <div className="flex rounded-md p-4 mb-4 md:mb-8 w-full grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-6 bg-gray-100">
        <div className="xl:col-span-2">
          <SearchBox
            id="search"
            label={"Search For Document"}
            svgType={"document"}
            placeholder={"Name"}
            value={value}
            searchHandler={() => {
              searchHandeler();
            }}
          />
        </div>

        <div className="xl:col-span-1">
          <DateBox
            dependentOn={true}
            id={"Ud1"}
            label="Upload Date From"
            //minDate={moment().add(0, "days").toDate()}
            onChange={handleDateBox}
            name={"FromDate"}
            value={fromDateState ? new Date(fromDateState) : ""}
          />
        </div>
        <div className="xl:col-span-1">
          <DateBox
            dependentOn={true}
            id={"Ud1"}
            label="Upload Date Till"
            //minDate={moment().add(0, "days").toDate()}
            onChange={handleDateBox}
            name={"ToDate"}
            value={toDateState ? new Date(toDateState) : ""}
          //onChange={this.handleChange}
          />
        </div>
      </div>

      <div>
        <span
          onClick={() => uploaderPanel()}
          className="absolute z-20  p-3 md:py-2 h-7 right-0 bg-gray-300 cursor-pointer  flex items-center"
        >
          +
        </span>
      </div>

      <div
        className={
          (isOpen ? "" : "hidden") +
          " z-20 fixed top-12 left-0 w-full h-full bg-black/20 p-6 grid place-content-center"
        }
      >
        <div className="bg-white w-70per h-60per p-6 rounded-lg">

          <div className="cursor-pointer text-right" onClick={() =>{
            setIsOpen(false);
            setExpanded("");
            setBackground("hidden");
          }}>&#10006;</div>

          <div className="h-80percent">
            <Uploader
              onUpload={(file, docTypeId) => {
                upload(file, docTypeId);
              }}
              docTypeId={"AgentDocuments"}
            />
          </div>

          <div className="sm:border-t sm:border-gray-200 sm:pt-5 w-full flex justify-center bg-white">
            <button
              id="submit"
              type="button"
              onClick={(e) => {
                uploadFile();
              }}
              className="inline-flex justify-center py-1 px-4 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            >
              Submit
            </button>
          </div>

        </div>
      </div>

      <div className="">
        <span
          onClick={() => uploaderPanel()}
          className={
            background +
            " z-30  top-10 left-0 right-0 bottom-0 w-full bg-gray-400 opacity-75"
          }
        />
      </div>

      {renderTiles()}
    </div>
  );
};

export default DocumentsView;
