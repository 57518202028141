import React, { useEffect, useState } from "react";
import Loader from "./common/Loader";
import TableComponent from "./common/TableComponent";
import Axios from "axios";
import moment from "moment";
import DateBox from "./controls/DateBox";
import * as constants from "../constants";
import { DateFormatter } from "./common/DateFormatter";
import { currencyFormat, SearchFilter } from "./common/commonFns";
import { AgentsRequests } from "../apiRequests/AgentsRequests";
import SearchBox from "./controls/SearchBox";
import ToolTip from "./common/ToolTip";
import Sms from "./common/sendMessage";

const dateFormatter = new DateFormatter();
const agentsRequests = new AgentsRequests();

const OverDues = () => {
  // constructor(props) {
  //   super(props);
  const [state, setState] = useState({
    hidden: "hidden",
    isLoaded: false,
    agentId: localStorage.getItem("agentId"),
    tableRows: [],
    tableEmpty: "hidden",
    invoiceDetailsData: [],
    firstRowIndex: 0,
    lastRowIndex: constants.NUMBEROFROWS - 1,
    filterOn: false,
    Mblhidden: "hidden",
    dataLength: 0,
    studentMobile: "",
    searchStudentValue: "",
    searchInvoiceValue: "",
    isOpen: false,
    expanded: "",
    background: "hidden",
    fromDate: "",
    toDate: "",
    overDueInvoiceData: [],
  });
  //   this.setPageIndexFn = this.setPageIndexFn.bind(this);
  //   this.handleDateBox = this.handleDateBox.bind(this);
  // }

  useEffect(() => {
    setState((prevState) => ({ ...prevState, hidden: "", isLoaded: true }));

    let startDate = localStorage.getItem("t2StartDate");
    let endDate = localStorage.getItem("t2EndDate");

    setState((prevState) => ({
      ...prevState,
      fromDate: startDate,
      toDate: endDate,
    }));
  }, []);

  const overDueInvoices = (startDate, endDate) => {
    setState((prevState) => ({
      ...prevState,
      hidden: "",
      isLoaded: true,
    }));

    agentsRequests.overDueInvoices(startDate, endDate).then((result) => {
      overDueInvoicesStudentDetails(startDate, endDate, result);
    });
  };

  const overDueInvoicesStudentDetails = (startDate, endDate, invoiceData) => {
    Axios.get(
      constants.BASEURL +
        "Agents/OverDueInvoiceDetails/" +
        state.agentId +
        "/" +
        startDate +
        "/" +
        endDate
    )
      .then((response) => {
        let invoiceDetailsData = response.data;
        let data = invoiceData;

        let FinalOverdueInvoiceData = [];
        let struct = {
          studentInvoiceID: "",
          studentInvoiceNo: "",
          studentName: "",
          invoiceAmt: "",
          receiptAmt: "",
          balanceAmt: "",
          studentEmail: "",
          studentDOB: "",
          studentMobile: "",
          studentNo: "",
          enrolmentNo: "",
          dueDate: "",
        };

        data.map((object) => {
          //console.log(JSON.stringify(invoiceDetailsData));
          const a = invoiceDetailsData.find(
            (element) => element.studentInvoiceID === object.studentInvoiceID
          );

          if (a) {
            struct.studentInvoiceID = object.studentInvoiceID;
            struct.studentInvoiceNo = a.invoiceNo;
            struct.studentName = a.studentName;
            struct.invoiceAmt = currencyFormat(object.invoiceAmt);
            struct.receiptAmt = currencyFormat(object.receiptAmt);
            struct.balanceAmt = currencyFormat(object.balanceAmt);
            struct.studentEmail = a.studentEmail;
            struct.invoiceDate = a.invoiceDate;
            struct.dueDate = a.dueDate;
            struct.studentMobile = a.studentMobile;
            struct.studentNo = a.studentNo;
            struct.enrolmentNo = a.enrolmentNo;
            FinalOverdueInvoiceData.push({ ...struct });
          }
        });

        setState((prevState) => ({
          ...prevState,
          invoiceDetailsData: FinalOverdueInvoiceData,
          dataLength: FinalOverdueInvoiceData.length,
        }));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const loadData = () => {
    let tableRows = [];
    let formatedRowData = {};
    let data = state.invoiceDetailsData;
    let firstRowIndex = state.firstRowIndex;
    let lastRowIndex = state.lastRowIndex;

    data = searchItems(data);

    let dataLength = data.length;
    if (dataLength < parseInt(firstRowIndex) + 1) {
      firstRowIndex = 0;
      lastRowIndex = constants.NUMBEROFROWS - 1;
    }

    if (data != "" && data != null) {
      data.map((object, index) => {
        if (index >= firstRowIndex && index <= lastRowIndex) {
          formatedRowData.ImgSrc =
            "https://ui-avatars.com/api/name=" +
            object.studentName +
            "/?background=random";
          formatedRowData.Label1 = object.studentName;
          formatedRowData.DescriptionClm1 =
            " Student No:" +
            object.studentNo +
            ", Enrolment No:" +
            object.enrolmentNo;
          formatedRowData.Label2 = object.studentInvoiceNo;
          let invoiceDate = moment(object.invoiceDate, "DD/MM/YYYY").format(
            "DD-MM-YYYY"
          );
          formatedRowData.DescriptionClm2 = "Invoice Date: " + invoiceDate;
          formatedRowData["StudentMobile"] = object.studentMobile;
          formatedRowData["Due Date"] = moment(
            object.dueDate,
            "DD/MM/YYYY"
          ).format("DD-MM-YYYY");
          formatedRowData["Student Mobile"] = object.studentMobile;
          formatedRowData["Invoiced"] = "$" + object.invoiceAmt;

          formatedRowData["Paid"] = "$" + object.receiptAmt;
          formatedRowData.Value = "$" + object.balanceAmt;
          // formatedRowData[" "] = (
          //   <div className="relative flex justify-end">
          //     <button
          //       Key={"message" + index}
          //       onClick={() => {
          //         this.eventHandeler(index, "SMS");
          //       }}
          //       className=" mx-2 my-1 w-5 h-5 inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          //     >
          //       <div className="tooltip">
          //         <svg
          //           className="w-5 h-5"
          //           fill="currentColor"
          //           viewBox="0 0 20 20"
          //           xmlns="http://www.w3.org/2000/svg"
          //         >
          //           <path
          //             fillRule="evenodd"
          //             d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z"
          //             clipRule="evenodd"
          //           />
          //         </svg>
          //         <ToolTip text={"Click to send a SMS"} />
          //       </div>
          //     </button>
          //   </div>
          // );
          tableRows.push({ ...formatedRowData });
        }
      });
    } else {
      //following line will unhide Nodata View
      setState((prevState) => ({ ...prevState, tableEmpty: "" }));
    }

    setState((prevState) => ({ ...prevState, tableRows: tableRows }));

    setState((prevState) => ({
      ...prevState,
      hidden: "hidden",
      isLoaded: false,
    }));
  };

  const eventHandeler = (index, event) => {
    let tableRows = state.tableRows;

    if (event === "SMS") {
      const stdMobile = tableRows[index]["Student Mobile"];
      if (stdMobile) {
        setState((prevState) => ({
          ...prevState,
          studentMobile: stdMobile,
          Mblhidden: "",
          background: "",
        }));
      } else {
        alert("Mobile Number not Available");
      }
    } else if (event === "close" || event === "success") {
      setState((prevState) => ({
        ...prevState,
        studentMobile: "",
        Mblhidden: "hidden",
        background: "hidden",
      }));
    }
  };

  useEffect(() => {
    renderMobile();
  }, [state.studentMobile, state.Mblhidden, state.background]);

  const renderMobile = () => {
    return (
      <Sms
        number={state.studentMobile}
        callBack={(event) => eventHandeler(0, event)}
      />
    );
  };

  const searchItems = (data) => {
    let originalData = data;
    let filterOn = false;

    if (state.searchStudentValue != "") {
      data = SearchFilter(data, state.searchStudentValue, "studentName");
      filterOn = true;
    }

    if (state.searchInvoiceValue != "") {
      data = SearchFilter(data, state.searchInvoiceValue, "studentInvoiceNo");
      filterOn = true;
    }

    if (data.length === originalData.length) {
      filterOn = false;
    }

    setState((prevState) => ({
      ...prevState,
      filterOn: filterOn,
      dataLength: data.length,
    }));
    return data;
  };

  const renderTable = () => {
    let tableHeadings = [
      "Student Details",
      "Invoice Details",
      "Due Date",
      "Student Mobile",
      "Invoiced",
      "Paid",
      "Balance",
      " ",
    ];
    let tableRows = state.tableRows;

    if (tableRows.length >= 1) {
      return (
        <TableComponent
          tableHeadings={tableHeadings}
          tableRows={tableRows}
          filterOn={state.filterOn}
          numberOfElementsPerPage={constants.NUMBEROFROWS}
          dataLength={state.dataLength}
          setPageIndexFn={setPageIndexFn}
          firstRowIndex={state.firstRowIndex}
          lastRowIndex={state.lastRowIndex}
          clmTwoWidth={"w-40"}
        />
      );
    } else {
      return (
        <div
          className={
            state.tableEmpty + " text-gray-600 w-full flex justify-center mt-20"
          }
        >
          <h1>
            There is No Data Available to Display, please reset filters if any
            applied.
          </h1>
        </div>
      );
    }
  };

  const setPageIndexFn = (firstRowIndex, lastRowIndex) => {
    setState((prevState) => ({
      ...prevState,
      firstRowIndex: firstRowIndex,
      lastRowIndex: lastRowIndex,
    }));
  };

  const searchHandeler = (id) => {
    if (id === "student") {
      var searchValue = document.getElementById("student").value;
      setState((prevState) => ({
        ...prevState,
        searchStudentValue: searchValue,
        searchInvoiceValue: "",
      }));
    } else if (id === "invoice") {
      var searchValue = document.getElementById("invoice").value;
      setState((prevState) => ({
        ...prevState,
        searchInvoiceValue: searchValue,
        searchStudentValue: "",
      }));
    }
  };

  useEffect(() => {
    loadData();
  }, [
    state.invoiceDetailsData,
    state.dataLength,
    state.firstRowIndex,
    state.lastRowIndex,
    state.searchInvoiceValue,
    state.searchStudentValue,
  ]);

  const handleDateBox = (e, name) => {
    let selectedDate = e;

    let fromDate = new Date(dateFormatter.formatterThree(state.fromDate));
    let toDate = new Date(dateFormatter.formatterThree(state.toDate));
    let sDate = new Date(dateFormatter.formatterThree(selectedDate));
    localStorage.setItem("dateRangeLabel2", "Custom Range");
    if (name === "FromDate") {
      let startDate = dateFormatter.formatterTwo(sDate);
      let endDate = dateFormatter.formatterTwo(toDate);
      localStorage.setItem("t2StartDate", startDate);
      setState((prevState) => ({
        ...prevState,
        fromDate: startDate,
        isOpen: false,
        expanded: "",
        background: "hidden",
      }));
    } else if (name === "ToDate") {
      let startDate = dateFormatter.formatterTwo(fromDate);
      let endDate = dateFormatter.formatterTwo(sDate);
      localStorage.setItem("t2EndDate", endDate);
      setState((prevState) => ({
        ...prevState,
        toDate: endDate,
        isOpen: false,
        expanded: "",
        background: "hidden",
      }));
    }
  };

  useEffect(() => {
    if (state.fromDate && state.toDate) {
      overDueInvoices(state.fromDate, state.toDate);
    }
  }, [
    state.fromDate,
    state.isOpen,
    state.expanded,
    state.background,
    state.toDate,
  ]);

  return (
    <div className="relative mt-6 align-middle min-h-full ">
      <Loader loaded={state.isLoaded} hidden={state.hidden}></Loader>
      <div className={state.Mblhidden + " z-40  Mobile-View"}>
        {renderMobile()}
      </div>
      <div className="rounded-md p-4 mb-4 md:mb-8 w-full grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-6 bg-gray-100">
        <div className="xl:col-span-2">
          <SearchBox
            id={"student"}
            label={"Search For Student Name"}
            svgType={"student"}
            placeholder={"Name"}
            value={state.searchStudentValue}
            searchHandler={() => {
              searchHandeler("student");
            }}
          />
        </div>
        <div className="xl:col-span-2">
          <SearchBox
            id={"invoice"}
            label={"Search For Invoice Number"}
            svgType={"document"}
            placeholder={"Number"}
            value={state.searchInvoiceValue}
            searchHandler={() => {
              searchHandeler("invoice");
            }}
          />
        </div>

        <div className="xl:col-span-1">
          <DateBox
            dependentOn={true}
            label="Due Date From"
            //minDate={moment().add(0, "days").toDate()}
            onChange={handleDateBox}
            name={"FromDate"}
            value={state.fromDate ? new Date(state.fromDate) : ""}
          />
        </div>
        <div className="xl:col-span-1">
          <DateBox
            dependentOn={true}
            id={"Pd1"}
            label="Due Date Till"
            //minDate={moment().add(0, "days").toDate()}
            onChange={handleDateBox}
            name={"ToDate"}
            value={state.toDate ? new Date(state.toDate) : ""}
            //onChange={this.handleChange}
          />
        </div>
      </div>

      <div>{renderTable()}</div>
      <span
        onClick={() => eventHandeler(0, "close")}
        className={
          state.background +
          " z-30 fixed top-0 left-0 right-0 bottom-0 h-full w-full bg-black opacityBackground"
        }
      />
    </div>
  );
};

export default OverDues;
